import { ComponentHelper } from "../../core/ComponentHelper";
import HtmlControl from "../../core/HtmlControl";
import { isInsideHtmlEditor } from "../../core/HtmlEditor";
import { XNode } from "../../core/XNode";

export default class MenuBar extends HtmlControl {

    async prepare() {

        if (isInsideHtmlEditor(this)) {
            return;
        }

        await ComponentHelper.waitForReady();

        const nav = document.createElement("nav");
        for(const child of Array.from(this.children)) {
            child.remove();
            nav.appendChild(child);
        }

        XNode.render(this, <div>
            <input type="checkbox"/>
            <div class="image-div"/>
        </div>);
        this.appendChild(nav);


    }
}

customElements.define("menu-bar", MenuBar);
