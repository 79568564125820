const prefix = "styler-";

const onDomReady = (resolve) => {

    if (document.readyState !== "loading") {
            resolve();
        return;
    }

    const d = () => {
        document.removeEventListener("DOMContentLoaded", d);
        resolve();
    };
    document.addEventListener("DOMContentLoaded", d);
};

let style: HTMLStyleElement;

const list = [
    'styler-on-enter',
    'styler-on-enter-effect-1',
    'styler-on-enter-effect-2',
    'styler-on-hover',
    'styler-on-hover-effect-1',
    'styler-on-hover-effect-2',
    'styler-on-leave',
    'styler-on-leave-effect-1',
    'styler-on-leave-effect-2',
    'styler-on-above-body',
    'styler-on-above-body-effect-1',
    'styler-on-above-body-effect-2',
    'styler-on-top-half',
    'styler-on-top-half-effect-1',
    'styler-on-top-half-effect-2',
];

const keyFrameMap = new Map<string, { id: string, element: HTMLElement, defaults: [string,any][]}>();

let n = Date.now();

const tryParse = (n) => { 
    try {
        return JSON.parse(n);
    } catch (e) {
        console.warn(e);
    }
};

const css = new CSSStyleSheet({

});

const stylerCss = new CSSStyleSheet({

});

const stylerMap = new Set();

const updateStylerMap = (key, value, av = value) => {

    const selector = `[${key}=${CSS.escape(value)}]`;
    const match = `${key}=${value},${av}`
    if (stylerMap.has(match)) {
        return;
    }
    stylerMap.add(match);
    stylerCss.insertRule(`${selector} {
        --${key}: ${av};
    }`);
};

document.adoptedStyleSheets = document.adoptedStyleSheets?.length
    ? [... document.adoptedStyleSheets, css, stylerCss]
    : [css, stylerCss];

const updateKeyFrameAttribute = (e: HTMLElement, a) => {
    const style = e.style;
    if (!style) {
        return;
    }
    const animationDefJson = e.getAttribute(a);
    if (!animationDefJson) {
        return;
    }
    const animationObj = tryParse(animationDefJson);
    if (animationObj === void 0) {
        return;
    }
    const minJson = JSON.stringify(animationObj);
    let v = keyFrameMap.get(minJson);
    if (!v) {
        // lets create one...
        const element = document.createElement("style");
        const frames = animationObj.keyframes ?? animationObj.keyFrames;
        if (!frames || !Array.isArray(frames)) {
            return;
        }
        const total = frames.length;
        let i = 0;
        for (const frame of frames) {
            frame.attributes = { ... frame };
            delete frame.attributes.offset;
            frame.offset ??= i === 0 ? 0 : i === total - 1 ? 1 :  i / (total - 1);
            i++;
        }
        const defaults = Object.entries(frames[0].attributes);
        v = { id: `internal-style-generated-${animationObj.title}-${n++}`, element, defaults };
        let styleText = `@keyframes ${v.id} {
            ${frames.map((f) => `${Math.floor(f.offset * 100)}% {
                ${Object.entries(f.attributes).map(([k,v]) => `${k}: ${v};`).join("\n")}
            }`).join("\n")}
        }`;

        css.insertRule(styleText);

        if (a.startsWith("styler-on-enter")) {
            // set defaults...
            styleText = `:not(animated-text):not([styler-entered])[${a}=${CSS.escape(animationDefJson)}],
            animated-text:not([styler-entered])[${a}=${CSS.escape(animationDefJson)}] > * {
                ${defaults.map(([k,v]) => `${k}: ${v};`).join("\t\n")}
            }
            `;
            css.insertRule(styleText);
        }

        

    }
    updateStylerMap(a, animationDefJson, v.id);
};

const updateStyles = (element: HTMLElement) => {
    for(const a of element.getAttributeNames()) {
        if (!a.startsWith(prefix))
            continue;
        if (list.includes(a)) {
            updateKeyFrameAttribute(element, a);
            continue;
        }
        const v = element.getAttribute(a);
        if (v !== "custom") {
            updateStylerMap(a, v);
        }
    }
};

onDomReady(() => {

    if(typeof (document as any).stylerExpansion === "undefined") {
        if (!document.body.hasAttribute("styler-expansion-enabled")) {
            return;
        }
    }


    // const prefix = document.body.hasAttribute("editing")
    //     ? "styler-on-"
    //     : "styler-";

    // set all attributes...
    const all = document.body.querySelectorAll("*");
    all.forEach(updateStyles);

    // we will only watch for changes here.

    const ao = new MutationObserver((mutations) => {
        for (const m of mutations) {

            const { type } = m;

            if (type === "childList") {
                const { addedNodes } = m;
                addedNodes.forEach(updateStyles);
                continue;
            }

            if (type === "attributes") {

                const { target, attributeName, oldValue } = m;

                if (!attributeName.startsWith(prefix)) {
                    continue;
                }

                const element = target as HTMLElement;
                if (list.includes(attributeName)) {
                    updateKeyFrameAttribute(element, attributeName);
                    continue;
                }

                const v = element.getAttribute(attributeName);
                if (oldValue === v && oldValue !== null) {
                    // this duplication occurs for no reason...
                    continue;
                }
                if (v && v !== "custom") {
                    updateStylerMap(attributeName, v);
                }
                continue;
            }
        }
    });
    ao.observe(document.body, {
        subtree: true,
        childList: true,
        attributes: true
    });


});