import { IEditableAttribute } from "../../core/EditableAttributes";
import HtmlControl from "../../core/HtmlControl";
import { isInsideHtmlEditor } from "../../core/HtmlEditor";
import { XNode } from "../../core/XNode";
import sanitize, { sanitizeDom } from "../../services/sanitize/sanitize";


export  default class InlineSvg extends HtmlControl {

    root: ShadowRoot;

    loading: any;
    loadingSrc: any;

    abortController: AbortController;

    static observedAttributes = ["src", "mode", "width", "height"];

    get editableAttributes(): IEditableAttribute[] {
        return [
            { name: "src", type: "image" },
            { name: "height", type: "number" },
            { name: "width", type: "number" },
            { name: "mode", type: "enum", values: [
                "shadow",
                "dom"
            ] }
        ];
    }

    get isShadowMode() {
        return this.getAttribute("mode") !== "dom";
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch(name) {
            case "src":
            case "mode":
                this.updateImage().catch(console.error);
                break;
            case "width":
                this.updateWidth();
                break;
            case "height":
                this.updateHeight();
                break;
        }
    }

    prepare() {
        this.updateImage().catch(console.error);
        this.updateWidth();
        this.updateHeight();
    }

    async updateImage() {

        const src = this.getAttribute("src");

        if(!src) {
            return;
        }

        if (src === this.loadingSrc) {
            return;
        }
        this.loadingSrc = src;

        this.abortController?.abort();

        const a = this.abortController = new AbortController();
        const signal = a.signal;

        this.innerHTML = "";
        if (this.isShadowMode) {
            this.root ??= this.attachShadow({ mode: "closed"});
            this.root.innerHTML = "";
        }

        try {
            const rs = await fetch(src, { signal });
            if (rs.status >= 400) {
                const text = await rs.text();
                const er = new Error(`$Http Error ${rs.status}`);
                (er as any).response = { status: rs.status, text };
                throw er;
            }
            let svgText = await rs.text();

            svgText = await this.sanitize(svgText, signal);

            if (signal.aborted) {
                return;
            }

            if(!this.isShadowMode) {
                await this.updateDomImage(svgText);
                this.loadingSrc = null;
                return;
            }
            await this.updateShadowImage(svgText);
            this.loadingSrc = null;
        } catch (error) {
            this.displayError(error);
            this.loadingSrc = null;
            const { response } = error;
            if (response) {
                console.error({ error, response })
                window.dispatchEvent(new ErrorEvent("error", { error }));
            } else {
              window.dispatchEvent(new ErrorEvent("error", { error }));
              console.error(error);
            }
        }
    }

    displayError(error: any) {
        this.root ??= this.attachShadow({ mode: "closed"});
        this.root.innerHTML = `<svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
                <path fill="#d02216" d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
        </svg>`;
    }

    async sanitize(svgText: string, signal)  {
        if (isInsideHtmlEditor(this)) {
            return await sanitizeDom(svgText, "image/svg+xml");
        }
        return await sanitize(svgText, "image/svg+xml");
    }

    async updateDomImage(svgText: string) {

        this.innerHTML = svgText

    }

    async updateShadowImage(svgText: string) {
        this.root ??= this.attachShadow({ mode: "closed"});
        this.root.innerHTML = svgText;
    }

    updateWidth() {
        let width: string | number = this.getAttribute("width");
        if (!width) {
            return;
        }
        width = Number.parseInt(width, 10);
        this.style.width = width + "px";
    }

    updateHeight() {
        let height: string | number = this.getAttribute("height");
        if (!height) {
            return;
        }
        height = Number.parseInt(height, 10);
        this.style.height = height + "px";
    }

}

customElements.define("inline-svg", InlineSvg);